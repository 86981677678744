.TestimonialsHead {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    width: 100%;
    height: 80px;
    text-align: center;
    background-color: #ECD8CF;
    padding-top: 80px;
}

.Testimonialsheading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    color: #ECD8CF;
    background-color: #1D5A40;
    font-size: xx-large;
    width: 50%;
    height: 80px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 10px 10px 10px 10px;
    box-shadow: rgba(0, 106, 44, 0.4) 10px 0px,
    rgba(0, 106, 44, 0.4) -10px 0px,
     rgba(0, 106, 44, 0.3) 20px 0px,
     rgba(0, 106, 44, 0.3) -20px 0px,
     rgba(0, 106, 44, 0.2) 30px 0px,
     rgba(0, 106, 44, 0.2) -30px 0px,
       rgba(0, 106, 44, 0.1) 40px 0px,
       rgba(0, 106, 44, 0.1) -40px 0px,
        rgba(0, 106, 44, 0.05) 50px 0px,
        rgba(0, 106, 44, 0.05) -50px 0px;
}

.Testimonials {
    display: flex;
    background-color: #ECD8CF;
    justify-content: left;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 400px;
    padding-top: 100px;
}

.TestimonialsCards {
    display: grid;
    margin: 5px 20px 25px 20px;
    height: 250px;
    width: 800px;
    min-width: 500px;
    border: 3px solid #fff;
    border-radius: 10px;
    padding: 30px;
}

.cardText {
    text-align: left;
    color: #1D5A40;
    font-size: medium;
}

.cardBy {
    text-align: right;
    color: #1D5A40;
    font-size: large;
}

@media screen and (max-width: 1000px) {

.Testimonialsheading {
    font-size: large;
}
.TestimonialsCards {
    min-width: 350px;
}
}