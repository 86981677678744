* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.Home,
.Footer,
.About,
.Testimonials,
.Register,
.Nav {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
}
